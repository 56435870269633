import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';
import { CreateOrUpdateAdmin } from '../types/Staff';

export const useUpdateAdminUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      userId,
      updatedAdmin,
    }: {
      userId: number;
      updatedAdmin: CreateOrUpdateAdmin;
    }) => staffApi.updateAdminUser(userId, updatedAdmin),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminUsers');
        message.success('Usuário atualizado com sucesso');
      },
      onError: (_, variables) => {
        message.error(
          `Erro ao atualizar usuário ${variables.updatedAdmin.fullName}`
        );
      },
    }
  );
};
