import {
  BulbOutlined,
  ControlOutlined,
  DesktopOutlined,
  FileTextOutlined,
  FireOutlined,
  LinkOutlined,
  PlaySquareOutlined,
  SearchOutlined,
  SettingOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Col, Divider, Input, Row } from 'antd';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { BasicCard } from '../components/BasicCard';
import { useVerifyPermission } from '../hooks/useVerifyPermission';

export const DashboardScreen: FC = () => {
  const [permissionMaster, isLoading] = useVerifyPermission();

  const arrayItens = [
    {
      title: 'Tipos de Clientes',
      text: 'Cadastra, edita e configura dados do cliente',
      button: 'Acessar',
      buttonLink: '/clientes',
      icon: <UserDeleteOutlined />,
    },
    {
      title: 'Gestão de perfil',
      text: 'Cadastra, edita e configura dados do perfil',
      button: 'Acessar',
      buttonLink: '',
      icon: <SettingOutlined />,
    },
    {
      title: 'Permissões',
      text: 'Cadastra, edita e configura as permissões',
      button: 'Acessar',
      buttonLink: '/permissions',
      icon: <FileTextOutlined />,
    },
    {
      title: 'Segmentos',
      text: 'Crie, edite e ordene Segmentos.',
      linkName1: 'Gerenciamento',
      link1: '/screens/segments/manage',
      linkName2: 'Ordenação',
      link2: '/screens/segments/ordering',
      icon: <ControlOutlined />,
    },
    {
      title: 'Assets',
      text: 'Cadastra, edita e configura todos os assets',
      button: 'Acessar',
      buttonLink: '/screens/Assets',
      icon: <DesktopOutlined />,
    },
    {
      title: 'Programas',
      text: 'Cadastra, edita e configura todos os programas',
      button: 'Acessar',
      icon: <PlaySquareOutlined />,
      buttonLink: '/programs',
    },
    {
      title: 'Assinaturas',
      text: 'Cadastra, edita e configura todas as assinaturas',
      icon: <FireOutlined />,
      linkName2: 'Relatório de Cancelamento',
      link2: '/assinaturas/cancelamentos/agendas',
    },
    {
      title: 'Gestão de funil',
      text: 'Faça a gestão dos funis, adicionando novos ou gerenciando os atuais.',
      icon: <BulbOutlined />,
      linkName1: 'Upsell',
      link1: '/upsell/funnel',
      linkName2: 'Cancelamento',
      link2: '/unsubscribe/funnel',
    },
    {
      title: 'Financeiro e Juridico',
      text: 'Criação e edição de automações para o financeiro.',
      icon: <FileTextOutlined />,
      linkName1: 'Anonimização LGPD',
      link1: '/lgpd',
      linkName2: 'Agendamento Freezing',
      link2: '/freezing',
    },
    {
      title: 'Ofertas',
      text: 'Criação e edição de ofertas por produtos',
      icon: <FileTextOutlined />,
      button: 'Acessar',
      buttonLink: permissionMaster ? '/products' : undefined,
    },
    {
      title: 'Gestão de Modais',
      text: 'Cadastra, edita e configura todos os modais pós login.',
      icon: <FileTextOutlined />,
      button: 'Acessar',
      linkName1: 'Gerenciamento',
      link1: '/screens/modals',
      linkName2: 'Ordenação',
      link2: '/screens/modals/ordering',
      buttonLink: '/screens/modals',
    },
    {
      title: 'Gestão de Cupons',
      text: 'Cadastra, edita e configura todos os cupons de checkout.',
      icon: <FileTextOutlined />,
      button: 'Acessar',
      buttonLink: '/screens/coupons',
    },
    {
      title: 'Order Bump',
      text: 'Cadastra, edita e configura todos os order bumps de checkout.',
      icon: <FileTextOutlined />,
      button: 'Acessar',
      buttonLink: '/screens/order-bump',
    },
    {
      title: 'Gestão de links',
      text: 'Gerador de links, encurtador de links e modal redirect',
      icon: <LinkOutlined />,
      linkName1: 'Gerador de links',
      link1: '/link-generator',
      linkName2: 'Encurtador de links',
      link2: '/link-shortner',
      linkName3: 'Modal Redirect',
      link3: '/modal-redirect',
    },
  ];

  const [item, setItem] = useState(arrayItens);

  useEffect(() => {
    setItem(arrayItens);
  }, [isLoading]);

  const onChange = (event: ChangeEvent<{ value: string }>) => {
    const text = event.target.value;
    if (text === '') {
      return setItem(arrayItens);
    }
    // eslint-disable-next-line
    const array: any[] = [];
    arrayItens.forEach(param => {
      if (param.title.toLowerCase().includes(text.toLowerCase())) {
        array.push(param);
      }
    });
    setItem(array);
  };

  return (
    <>
      <Row>
        <Col span={14}>
          <h1>Admin</h1>
        </Col>
        <Col span={10}>
          <Input
            placeholder='Buscar por...'
            suffix={<SearchOutlined />}
            onChange={onChange}
            data-testid='input'
          />
        </Col>
        <Divider />
      </Row>
      <Row>
        {item.map((param, index) => {
          return (
            <Col
              key={`col-dashboard-${index}`}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              style={{ marginTop: 30 }}
            >
              <BasicCard
                key={index}
                title={param.title}
                text={param.text}
                link1={param.link1}
                linkName1={param.linkName1}
                link2={param.link2}
                linkName2={param.linkName2}
                link3={param.link3}
                linkName3={param.linkName3}
                button={param.button}
                icon={param.icon}
                buttonLink={param.buttonLink}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
