import { message } from 'antd';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';

export const useUnlinkUsers = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries('roles');
    message.success('Desvinculação realizada com sucesso.');
  }, [queryClient]);

  const onError = useCallback(() => {
    message.error('Desvinculação não realizada. Tente novamente.');
  }, []);

  const { mutateAsync: unlikeUsers, isLoading: isLoadingUnlinkUsers } =
    useMutation((roleId: number) => staffApi.unlinkUsers(roleId), {
      onSuccess,
      onError,
    });
  return { unlikeUsers, isLoadingUnlinkUsers };
};
