import { format, subDays, subMonths } from 'date-fns';

export const PERIOD_OPTIONS = [
  {
    label: 'Último mês',
    value: 'lastMonth',
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: 'Últimos 3 meses',
    value: 'last3Months',
    startDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: 'Últimos 12 meses',
    value: 'last12Months',
    startDate: format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: 'Últimos 2 meses',
    value: 'last2Months',
    startDate: format(subMonths(new Date(), 2), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
];

export const SEGMENTED_PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 'last12months',
    startDate: format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: '3 Meses',
    value: 'last3months',
    startDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: '30 Dias',
    value: 'last30days',
    startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  {
    label: '7 Dias',
    value: 'last7days',
    startDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
];
