import { message } from 'antd';
import { useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';

export const useDeleteRole = () => {
  const queryClient = useQueryClient();

  const deleteRole = async (roleId: number) => {
    await staffApi.deleteRole(roleId);
    queryClient.invalidateQueries('roles');
    message.success('Papel excluído com sucesso!');
  };

  return {
    deleteRole,
  };
};
