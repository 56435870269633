import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useContentProfiles = () => {
  const { data: contentProfiles, isLoading: contentProfilesLoading } = useQuery(
    {
      queryKey: ['content-profiles'],
      queryFn: () => customerApi.fetchContentProfiles(),
    }
  );

  return { contentProfiles, contentProfilesLoading };
};
