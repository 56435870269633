import { QuestionCircleOutlined } from '@ant-design/icons';
import { TableComponent } from '@atoms';
import { Col, Progress, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import { ContentWatchedCount, UsageEngagement } from '../../types/customer';

const { Text } = Typography;

type ContentsWatchedType = {
  totalWatchedOfLastContent: UsageEngagement['totalWatchedOfLastContent'];
};

export const ContentsWatched: React.FC<ContentsWatchedType> = ({
  totalWatchedOfLastContent,
}) => {
  const tableColumns: ColumnsType<ContentWatchedCount> = [
    {
      title: 'Programa',
      dataIndex: 'programName',
      key: 'programName',
    },
    {
      title: 'Aula',
      dataIndex: 'contentName',
      key: 'contentName',
    },
    {
      title: 'Total assistido',
      dataIndex: 'totalWatched',
      key: 'totalWatched',
      render: (watchedCount: number) => {
        return <Progress percent={watchedCount} steps={4} />;
      },
    },
  ];

  return (
    <Col span={24}>
      <Space
        direction='vertical'
        size='middle'
        style={{ display: 'flex', width: '100%' }}
      >
        <Row justify={'start'} align={'middle'}>
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 300 }}>
            Aulas iniciadas pelo Cliente
          </Text>
          <Tooltip
            placement='rightTop'
            title='Representa as aulas que o cliente apertou o play nos últimos 30 dias.'
            overlayInnerStyle={{
              marginTop: '-8px',
            }}
          >
            <QuestionCircleOutlined
              style={{ marginLeft: 8, fontSize: '18px' }}
            />
          </Tooltip>
        </Row>

        <Row style={{ marginTop: '4px', display: 'flex' }}>
          <Col flex={1}>
            <TableComponent
              columns={tableColumns}
              data={totalWatchedOfLastContent}
              pageSize={10}
            />
          </Col>
        </Row>
      </Space>
    </Col>
  );
};
