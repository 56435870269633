import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SwitchController, TextAreaController } from '@organisms';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  Controller as ControllerHookForm,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

import { BackButton } from 'src/app/components/BackButton';
import { useQueryParams } from 'src/app/hooks/useQueryParams';
import { CancelationButtonTitleFormFields } from 'src/features/Funnel/Components/formFields/CancelationButtonTitleFormFields';
import { useTryToRequestCreatingSpecialistFunnelPage } from 'src/features/Funnel/hooks/specialist/useTryToRequestCreatingSpecialistFunnelPage';
import { useUpdateScepialistFunnelPage } from 'src/features/Funnel/hooks/specialist/useUpdateSpecialistFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import {
  EnableFormDataKey,
  SpecialistFormData,
} from 'src/features/Funnel/types/specialistPageTypings';

import { TitleFunnelPage } from '../../../Components/TitleFunnelPage';

export const FunnelSpecialistCarePageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState<string>('Modal de Suporte');
  const [isEditPage, setIsEditPage] = useState(false);

  const validateStringIfEnabled = (
    value: string | null | undefined,
    enabled: boolean
  ): boolean => {
    if (enabled && !value) {
      return false;
    }
    return true;
  };

  const zendeskTagsValidationSchema = yup.object({
    tagName: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          const contextValuesFromPrincipalSchema = (context as any).from[1]
            .value;
          return validateStringIfEnabled(
            value,
            contextValuesFromPrincipalSchema.enableZendesk
          );
        },
        message: 'O nome da opção é obrigatório',
      }),
    tagDescription: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          const contextValuesFromPrincipalSchema = (context as any).from[1]
            .value;
          return validateStringIfEnabled(
            value,
            contextValuesFromPrincipalSchema.enableZendesk
          );
        },
        message: 'A descrição da opção é obrigatória',
      }),
  });

  const validationSchema = yup.object({
    id: yup.string().nullable(),
    title: yup.string().required('O Título é obrigatório'),
    subtitle: yup.string().nullable(),
    buttonTitle: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          return validateStringIfEnabled(value, context.parent.enableEmail);
        },
        message: 'O Título do Botão é obrigatório',
      }),
    buttonTitleWhatsApp: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          return validateStringIfEnabled(value, context.parent.enableWhatsapp);
        },
        message: 'O Título do Botão WhatsApp é obrigatório',
      }),
    buttonTitleZendesk: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          return validateStringIfEnabled(value, context.parent.enableZendesk);
        },
        message: 'O Título do Botão Zendesk é obrigatório',
      }),
    email: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          return validateStringIfEnabled(value, context.parent.enableEmail);
        },
        message: 'O E-mail é obrigatório',
      }),
    whatsApp: yup
      .string()
      .nullable()
      .test({
        test: (value, context) => {
          return validateStringIfEnabled(value, context.parent.enableWhatsapp);
        },
        message: 'o WhatsApp é obrigatório',
      }),
    zendeskReasonTags: yup.array().of(zendeskTagsValidationSchema),
    interaction: yup
      .boolean()
      .required('o Interação com analista é obrigatório'),
    continueCancelationButtonTitle: yup.string().nullable(),
    keepSubscriptionButtonTitle: yup.string().nullable(),
  });

  const { control, handleSubmit, watch, setValue, getValues } =
    useForm<SpecialistFormData>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        cancellationFunnelId: '',
        title: '',
        subtitle: '',
        buttonTitle: null,
        buttonTitleWhatsApp: null,
        buttonTitleZendesk: null,
        email: null,
        whatsApp: null,
        zendeskReasonTags: [
          {
            tagName: '',
            tagDescription: '',
            finishFunnel: false,
          },
        ],
        interaction: false,
        enableEmail: true,
        enableWhatsapp: true,
        enableZendesk: true,
        continueCancelationButtonTitle: null,
        keepSubscriptionButtonTitle: null,
      },
    });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'zendeskReasonTags',
  });

  const isPermitedToDisableOption = (
    enableOptionFieldKey: EnableFormDataKey
  ) => {
    const formValues = getValues();
    const { enableEmail, enableWhatsapp, enableZendesk } = formValues;
    let isPermited = false;

    const enableOptions = {
      enableEmail,
      enableWhatsapp,
      enableZendesk,
    };
    delete enableOptions[enableOptionFieldKey];

    Object.keys(enableOptions).forEach(key => {
      const enableFormDataKey = key as EnableFormDataKey;
      if (enableOptions[enableFormDataKey] === true) {
        isPermited = true;
        return;
      }
    });

    return isPermited;
  };

  const history = useHistory();
  const { id } = useParams<ParamasProps>();
  const params = useQueryParams();
  const pageId = params.get('pageId');
  const [isUpdate, setIsUpdate] = useState(false);

  const { tryToRequestCreatingSpecialistFunnelPage, isLoading } =
    useTryToRequestCreatingSpecialistFunnelPage();

  const {
    handleGetOneSpecialistFunnelPage,
    handleUpdateSpecialistFunnelPage,
    isLoadingSpecialistPageFunnelUpdate,
  } = useUpdateScepialistFunnelPage(setValue, replace);

  const handleSpecialistCarePagePage = useCallback(
    async (e: Omit<SpecialistFormData, 'titlePage'>) => {
      if (isUpdate && pageId) {
        return handleUpdateSpecialistFunnelPage({ titlePage, ...e }, pageId);
      }
      return await tryToRequestCreatingSpecialistFunnelPage({
        titlePage: titlePage,
        ...e,
      });
    },
    [titlePage, isUpdate, pageId]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  useEffect(() => {
    if (pageId) {
      handleGetOneSpecialistFunnelPage(pageId, setTitlePage);
      setIsUpdate(true);
    }
  }, [pageId]);

  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '22px',
      }}
    >
      <BackButton />
      <Row style={{ justifyContent: 'center' }}>
        <Col
          span={12}
          style={{
            minWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <TitleFunnelPage
            titlePage={titlePage}
            setTitlePage={setTitlePage}
            isEditTitle={isEditPage}
            setIsEditTitle={setIsEditPage}
          />
          <Row>
            <Form
              layout='vertical'
              requiredMark={'optional'}
              style={{
                width: '100%',
                gap: '24px',
                display: 'flex',
                flexDirection: 'column',
              }}
              onFinish={handleSubmit(handleSpecialistCarePagePage)}
            >
              <Controller
                control={control}
                name='title'
                placeholder='Escreva o principal título da página.'
                defaultValue={control._defaultValues.title}
                autoComplete='off'
                label='Título Principal'
                showCount={true}
                maxLength={250}
                required
              />
              <TextAreaController
                control={control}
                name='subtitle'
                placeholder='Área de texto'
                defaultValue={control._defaultValues.subtitle}
                autoComplete='off'
                label='Subtítulo'
                isMaxLength={true}
                maxLength={250}
                row={2}
                required={false}
              />
              {/*section email*/}
              <Row
                style={{
                  marginTop: '5px',
                  marginBottom: '5px',
                  gap: '8px',
                }}
              >
                <ControllerHookForm
                  control={control}
                  name='enableEmail'
                  render={({ field }) => {
                    return (
                      <>
                        <Switch
                          checked={field.value}
                          onChange={enable => {
                            if (!enable) {
                              if (!isPermitedToDisableOption('enableEmail'))
                                return;
                              setValue('buttonTitle', null);
                              setValue('email', null);
                            }
                            setValue('enableEmail', enable);
                          }}
                        />
                        <Typography>Email</Typography>
                      </>
                    );
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', gap: '24px', width: '100%' }}>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='buttonTitle'
                    placeholder='Título do botão'
                    defaultValue={
                      control._defaultValues.buttonTitle ?? undefined
                    }
                    autoComplete='off'
                    label='Título do botão e-mail'
                    showCount={true}
                    maxLength={250}
                    disabled={!watch('enableEmail')}
                    required={watch('enableEmail')}
                  />
                </Col>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='email'
                    placeholder='mailto:email@queimadiaria.com'
                    defaultValue={control._defaultValues.email ?? undefined}
                    autoComplete='off'
                    label='E-mail'
                    showCount={true}
                    maxLength={250}
                    disabled={!watch('enableEmail')}
                    required={watch('enableEmail')}
                  />
                </Col>
              </Row>

              {/*section whatsapp*/}
              <Row
                style={{
                  marginTop: '5px',
                  marginBottom: '5px',
                  gap: '8px',
                }}
              >
                <ControllerHookForm
                  control={control}
                  name='enableWhatsapp'
                  render={({ field }) => {
                    return (
                      <>
                        <Switch
                          checked={field.value}
                          onChange={enable => {
                            if (!enable) {
                              if (!isPermitedToDisableOption('enableWhatsapp'))
                                return;
                              setValue('buttonTitleWhatsApp', null);
                              setValue('whatsApp', null);
                            }
                            setValue('enableWhatsapp', enable);
                          }}
                        />
                        <Typography>Whatsapp</Typography>
                      </>
                    );
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', gap: '24px', width: '100%' }}>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='buttonTitleWhatsApp'
                    label='Título do botão WhatsApp'
                    placeholder='Título do botão'
                    defaultValue={
                      control._defaultValues.buttonTitleWhatsApp ?? undefined
                    }
                    autoComplete='off'
                    showCount={true}
                    maxLength={250}
                    disabled={!watch('enableWhatsapp')}
                    required={watch('enableWhatsapp')}
                  />
                </Col>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='whatsApp'
                    label='WhatsApp'
                    placeholder='https://wa.me/55DDNumero'
                    defaultValue={control._defaultValues.whatsApp ?? undefined}
                    autoComplete='off'
                    showCount={true}
                    maxLength={250}
                    disabled={!watch('enableWhatsapp')}
                    required={watch('enableWhatsapp')}
                  />
                </Col>
              </Row>

              {/*section zendesk*/}
              <Row
                style={{
                  marginTop: '5px',
                  marginBottom: '5px',
                  gap: '8px',
                }}
              >
                <ControllerHookForm
                  control={control}
                  name='enableZendesk'
                  render={({ field }) => {
                    return (
                      <>
                        <Switch
                          checked={field.value}
                          onChange={enable => {
                            if (!enable) {
                              if (!isPermitedToDisableOption('enableZendesk'))
                                return;
                              setValue('buttonTitleZendesk', null);
                            }
                            if (!enable || (enable && fields.length === 0)) {
                              setValue('zendeskReasonTags', [
                                {
                                  tagName: '',
                                  tagDescription: '',
                                  finishFunnel: false,
                                },
                              ]);
                            }
                            setValue('enableZendesk', enable);
                          }}
                        />
                        <Typography>Zendesk</Typography>
                      </>
                    );
                  }}
                />
              </Row>
              <Row
                style={{ flexDirection: 'column', gap: '24px', width: '100%' }}
              >
                <Row>
                  <Col flex={1}>
                    <Controller
                      control={control}
                      name='buttonTitleZendesk'
                      label='Título do botão Zendesk'
                      placeholder='Título do botão'
                      defaultValue={
                        control._defaultValues.buttonTitleZendesk ?? undefined
                      }
                      autoComplete='off'
                      showCount={true}
                      maxLength={250}
                      disabled={!watch('enableZendesk')}
                      required={watch('enableZendesk')}
                    />
                  </Col>
                </Row>
                {fields.map((field, index) => (
                  <Row
                    key={field.id}
                    style={{
                      flexDirection: 'row',
                      gap: '24px',
                      alignItems: 'center',
                    }}
                  >
                    <Card
                      size='small'
                      title={`opção ${index + 1}`}
                      style={{ width: '100%' }}
                      extra={
                        <Button
                          type='text'
                          style={{
                            minWidth: 'auto',
                            display: 'block',
                          }}
                          onClick={() => remove(index)}
                          disabled={fields.length <= 1}
                        >
                          <DeleteOutlined
                            style={{
                              fontSize: '16px',
                              cursor: 'pointer',
                            }}
                          />
                        </Button>
                      }
                    >
                      <Row style={{ gap: '14px' }}>
                        <Col flex={1}>
                          <Controller
                            control={control}
                            name={`zendeskReasonTags[${index}].tagDescription`}
                            label={`Nome da opção ${index + 1}`}
                            placeholder='Ex.: Dificuldade e problemas de uso da plataforma'
                            defaultValue={field.tagDescription}
                            autoComplete='off'
                            showCount={true}
                            maxLength={250}
                            disabled={!watch('enableZendesk')}
                            required={watch('enableZendesk')}
                          />
                        </Col>
                        <Col flex={1}>
                          <Controller
                            control={control}
                            name={`zendeskReasonTags[${index}].tagName`}
                            label={`Tag da opção ${index + 1}`}
                            placeholder='Ex.: comprei_e_estou_dentro_do_período_de_garantia__dificuldade_e_problemas_de_uso_da_plataforma'
                            defaultValue={field.tagName}
                            autoComplete='off'
                            showCount={true}
                            maxLength={250}
                            disabled={!watch('enableZendesk')}
                            required={watch('enableZendesk')}
                          />
                        </Col>
                        <Col flex={1}>
                          <SwitchController
                            control={control}
                            name={`zendeskReasonTags[${index}].finishFunnel`}
                            sideLabel
                            rightLabel='Finalizar funil'
                            defaultValue={field.finishFunnel}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                ))}
                <Row justify='center' align={'middle'}>
                  <Col>
                    <Button
                      type='text'
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        append({
                          tagName: '',
                          tagDescription: '',
                          finishFunnel: false,
                        });
                      }}
                      disabled={fields.length >= 10 || !watch('enableZendesk')}
                    >
                      Adicionar mais uma opção
                    </Button>
                  </Col>
                </Row>
              </Row>

              <SwitchController
                control={control}
                name='interaction'
                label='Interação com analista'
                defaultValue={control._defaultValues.interaction}
                required
                description='Ativar obrigatoriedade de conversa com analista'
              />
              <CancelationButtonTitleFormFields control={control} />

              <Divider orientation='center' />
              <Row style={{ justifyContent: 'end' }}>
                <Row style={{ gap: '8px' }}>
                  <Button type='default' onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={
                      isUpdate ? isLoadingSpecialistPageFunnelUpdate : isLoading
                    }
                  >
                    Salvar
                  </Button>
                </Row>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
