import { FormResponse } from '../interfaces/offersInterface';
import { generateImage } from './createFormDataForImage';

export async function createFormDataFromFormResponse(
  formResponse: FormResponse,
  productId: string
): Promise<FormData> {
  const formData = new FormData();
  const offerDuoCode = '43';
  const offerFamilyCode = '44';

  formData.append('productSellGroupId', productId);
  formData.append('offerName', formResponse.offerName);
  formData.append('offerDescription', formResponse.offerDescription);
  formData.append('offerValue', formResponse.fullPrice);
  formData.append('displayPrice', formResponse.displayPrice);
  formData.append('offerInstallments', formResponse.installments);
  formData.append('startDate', formResponse.startDate);
  formData.append('endDate', formResponse.endDate);
  formData.append('facebookPixels', formResponse.facebookPixels);
  const tagValue = formResponse.isMailPromotional ? 'promotional' : '';
  formData.append('tag', tagValue);
  formData.append('renewalOfferId', formResponse.renewalOfferId);
  formData.append('disclaimer', formResponse.disclaimer);
  formData.append('googleAnalyticsIds', formResponse.googleAnalyticsIds);
  formData.append('privacyPolicyRef', formResponse.privacyPolicyRef);
  formData.append('purchaseBtnLabel', formResponse.purchaseBtnLabel);
  formData.append('subscriptionPeriod', formResponse.subscriptionPeriod);
  formData.append('termsRef', formResponse.termsRef);
  formData.append('replaceDefaultOffer', formResponse.offerStandart.toString());
  formData.append('isDisplayCoupon', formResponse.isDisplayCoupon.toString());
  formData.append('renewalDisabled', formResponse.renewalDisabled.toString());
  formData.append('isAvailableNuPay', formResponse.isAvailableNuPay.toString());
  formData.append(
    'isAvailablePagaleve',
    formResponse.isAvailablePagaleve.toString()
  );
  formData.append('profileId', formResponse.profileId);
  formData.append('warrantyDays', formResponse.warrantyDays);

  if (formResponse.isPhysicProduct) {
    formData.append('primaryColor', formResponse.physicProduct.primaryColor);
    formData.append('footerText', formResponse.physicProduct.footerText);
    formData.append(
      'isFreeShipping',
      formResponse.physicProduct.isFreeShipping.toString()
    );
    formData.append('title', formResponse.physicProduct.seo.title);
    formData.append('siteName', formResponse.physicProduct.seo.siteName);
    formData.append(
      'keywords',
      `[${formResponse.physicProduct.seo.keywords
        .map(keyWord => `"${keyWord}"`)
        .join(', ')}]`
    );
    formData.append(
      'smallDescription',
      formResponse.physicProduct.seo.smallDescription
    );
    formData.append(
      'largeDescription',
      formResponse.physicProduct.seo.largeDescription
    );

    formResponse.physicProduct.seo.image instanceof Object &&
      formData.append(
        'image',
        await generateImage(formResponse.physicProduct.seo.image),
        formResponse.physicProduct.seo.image.file.name
      );

    formResponse.physicProduct.seo.favicon instanceof Object &&
      formData.append(
        'favicon',
        await generateImage(formResponse.physicProduct.seo.favicon),
        formResponse.physicProduct.seo.favicon.file.name
      );
  }

  if (!!formResponse.promotionalAmount)
    formData.append('promotionalAmount', formResponse.promotionalAmount);
  if (!!formResponse.qtdPromotionalDays)
    formData.append('qtdPromotionalDays', formResponse.qtdPromotionalDays);
  if (!!formResponse.qtdDelayedCaptureDays)
    formData.append(
      'qtdDelayedCaptureDays',
      formResponse.qtdDelayedCaptureDays
    );

  if (formResponse.plan) {
    switch (formResponse.plan) {
      case 'duo':
        formData.append('accessProfileId', offerDuoCode);
        break;
      case 'family':
        formData.append('accessProfileId', offerFamilyCode);
        break;
    }
  }

  formResponse.bannerWeb instanceof Object &&
    formData.append(
      'bannerWeb',
      await generateImage(formResponse.bannerWeb),
      formResponse.bannerWeb.file.name
    );

  formResponse.bannerMobile instanceof Object &&
    formData.append(
      'bannerMobile',
      await generateImage(formResponse.bannerMobile),
      formResponse.bannerMobile.file.name
    );

  formResponse.bannerSideWeb instanceof Object &&
    formData.append(
      'bannerSideWeb',
      await generateImage(formResponse.bannerSideWeb),
      formResponse.bannerSideWeb.file.name
    );

  formResponse.logo instanceof Object &&
    formData.append(
      'logo',
      await generateImage(formResponse.logo),
      formResponse.logo.file.name
    );

  //counter
  const hasOfferCounter =
    formResponse.counter.typeCounter === 'offerCounter' &&
    formResponse.hasCounter;
  formData.append('enableCounter', hasOfferCounter.toString());

  const hasUniqueVisitCounter =
    formResponse.counter.typeCounter === 'uniqueVisitCounter' &&
    formResponse.hasCounter;
  formData.append('enableUniqueVisitCounter', hasUniqueVisitCounter.toString());

  formData.append(
    'colorBackgroundCounter',
    formResponse.counter.offerCounter.bgCounter
  );
  formData.append(
    'timeoutRedirectUrl',
    formResponse.counter.uniqueVisitCounter.timeoutRedirectUrl
  );
  formData.append(
    'timerMinute',
    formResponse.counter.uniqueVisitCounter.timerMinute
  );
  formData.append(
    'timerSecond',
    formResponse.counter.uniqueVisitCounter.timerSecond
  );
  formData.append(
    'countdownPhrase',
    formResponse.counter.uniqueVisitCounter.countdownPhrase
  );
  formData.append(
    'cacheExpirationTime',
    formResponse.counter.uniqueVisitCounter.cacheExpirationTime
  );
  formResponse.counter.offerCounter.rightImg instanceof Object &&
    formData.append(
      'bannerRightWebCounter',
      await generateImage(formResponse.counter.offerCounter.rightImg),
      formResponse.counter.offerCounter.rightImg.file.name
    );

  formResponse.counter.offerCounter.rightImgMobile instanceof Object &&
    formData.append(
      'bannerRightMobileCounter',
      await generateImage(formResponse.counter.offerCounter.rightImgMobile),
      formResponse.counter.offerCounter.rightImgMobile.file.name
    );

  formResponse.counter.offerCounter.leftImg instanceof Object &&
    formData.append(
      'bannerLeftCounter',
      await generateImage(formResponse.counter.offerCounter.leftImg),
      formResponse.counter.offerCounter.leftImg.file.name
    );

  formResponse.counter.offerCounter.leftImgMobile instanceof Object &&
    formData.append(
      'bannerLeftMobileCounter',
      await generateImage(formResponse.counter.offerCounter.leftImgMobile),
      formResponse.counter.offerCounter.leftImgMobile.file.name
    );

  return formData;
}
