import { Space, Typography } from 'antd';
import { FC } from 'react';
import {
  Controller,
  ControllerProps,
  get,
  useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';

const { Text } = Typography;

interface ControlledInputProps
  extends Omit<ControllerProps<any>, 'control' | 'render'> {
  control: ControllerProps<any>['control'];
  label?: string;
  required?: boolean;
  render: (props: any) => JSX.Element;
}

export const ControlledInput: FC<ControlledInputProps> = ({
  control,
  name,
  label,
  render,
  required,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  return (
    <CustomSpace direction='vertical' size={4} style={{ width: '100%' }}>
      {label && (
        <Text type='secondary' style={{ alignContent: 'center' }}>
          {required && <Star>*</Star>}
          {label}
        </Text>
      )}
      <Controller
        name={name}
        control={control}
        render={field => {
          return render({
            ...field,
            status: error ? 'error' : undefined,
          });
        }}
        {...props}
      />
    </CustomSpace>
  );
};

const CustomSpace = styled(Space)`
  .ant-select,
  .ant-input-number,
  .ant-input,
  .ant-radio-group,
  .ant-checkbox-group,
  .ant-picker {
    width: 100%;
  }
`;

const Star = styled.strong`
  color: red;
  margin-right: 4px;
`;
