import { message } from 'antd';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';

export const useRemoveStaff = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries('adminUsers');
    message.success('Usuário excluído com sucesso.!');
  }, [queryClient]);

  const onError = useCallback(() => {
    message.error('O usuário não foi excluído. Tente novamente.');
  }, []);

  const { mutateAsync: removeStaff, isLoading: isLoadingRemoveStaff } =
    useMutation((userId: number) => staffApi.removeStaff(userId), {
      onSuccess,
      onError,
    });

  return { removeStaff, isLoadingRemoveStaff };
};
