import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export interface Admin {
  id: number;
  fullName: string;
  email: string;
  document: string;
  createdAt: string;
  firstAccess: string;
  sector: string;
  roles: string[];
  status: string;
  accessLevel: string;
}

export interface CreateOrUpdateAdmin {
  fullName: string;
  email: string;
  roles: number[];
  accessLevel: string;
}

export interface Staff {
  users: Admin[];
  total: number;
}

export const enum EStatus {
  Ativo = 'Ativo',
  Inativo = 'Inativo',
  'Convite enviado' = 'Convite enviado',
}

export const STATUS_COLOR: Record<string, string> = {
  Ativo: 'success',
  Inativo: 'error',
  'Convite enviado': 'processing',
};

export const STATUS_ICON: Record<string, React.ReactNode> = {
  Ativo: <CheckCircleOutlined />,
  Inativo: <CloseCircleOutlined />,
  'Convite enviado': <SyncOutlined />,
};

export interface IRole {
  id: string;
  name: string;
  description: string;
  users: number;
}
