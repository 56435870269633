import { QuestionCircleOutlined } from '@ant-design/icons';
import { TableComponent } from '@atoms';
import { Card, Col, Row, Space, Spin, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import { PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerDeviceAccess } from '../../hooks/useCustomerDeviceAccess';
import { DateRangePicker } from '../DateRangePicker';
import { EmptyState } from '../EmptyState';
import { ErrorState } from '../ErrorState';

const { Text } = Typography;

type DeviceAccessType = {
  customerId: string;
};

const dateFormat = 'DD/MM/YYYY';

export const DeviceAccess: React.FC<DeviceAccessType> = ({ customerId }) => {
  const [dateRange, setDateRange] = useState<string[]>([
    PERIOD_OPTIONS[3].startDate,
    PERIOD_OPTIONS[3].endDate,
  ]);

  const defaultDateRange: [moment.Moment, moment.Moment] = [
    moment(PERIOD_OPTIONS[3].startDate),
    moment(PERIOD_OPTIONS[3].endDate),
  ];
  const [startDate, endDate] = dateRange;

  const { classesByDevice, isError, isLoading, refetchOnError } =
    useCustomerDeviceAccess(customerId, startDate, endDate);

  if (isError)
    return (
      <Col span={12}>
        <Space
          direction='vertical'
          size='middle'
          style={{ display: 'flex', width: '100%' }}
        >
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 300 }}>
            Aulas visualizadas por dispositivo
          </Text>
          <Card
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '316px',
            }}
          >
            <ErrorState
              style={{ flex: 1 }}
              title='Erro ao carregar dados de aulas visualizadas por dispositivo'
              subtitle='Não foi possível carregar os dados de aulas visualizadas por dispositivo para este cliente, aperte o botão abaixo para recarregar'
              onClickButton={() => refetchOnError()}
            />
          </Card>
        </Space>
      </Col>
    );

  const tableColumns = [
    {
      title: 'Dispositivo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: 'Horário',
      dataIndex: 'hour',
      key: 'hour',
    },
  ];

  return (
    <Col span={24}>
      <Space
        direction='vertical'
        size='middle'
        style={{ display: 'flex', width: '100%' }}
      >
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Text style={{ margin: 0, fontSize: '16px', fontWeight: 300 }}>
              Aulas visualizadas por dispositivo
            </Text>
            <Tooltip
              placement='rightTop'
              title='Representa os dispositivos acessados nos últimos 2 meses'
              overlayInnerStyle={{
                marginTop: '-8px',
              }}
            >
              <QuestionCircleOutlined
                style={{ marginLeft: 8, fontSize: '18px' }}
              />
            </Tooltip>
          </Col>
          <Col>
            <DateRangePicker
              style={{
                width: '100%',
                maxWidth: '260px',
                height: '28px',
                color: '#1890ff',
                textAlign: 'right',
              }}
              format={dateFormat}
              defaultValue={defaultDateRange}
              onSelectDate={setDateRange}
            />
          </Col>
        </Row>

        {classesByDevice?.length !== 0 && !isLoading && classesByDevice && (
          <TableComponent
            columns={tableColumns}
            data={classesByDevice}
            pageSize={10}
          />
        )}

        {classesByDevice?.length === 0 && !isLoading && (
          <Row justify={'center'} align={'middle'}>
            <Card
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '316px',
              }}
            >
              <EmptyState
                style={{ flex: 1 }}
                title='Sem dados de aulas visualizadas por dispositivo'
                subtitle='Infelizmente não exite aulas visualizadas por dispositivo para esse cliente no período selecionado.'
              />
            </Card>
          </Row>
        )}

        {isLoading && (
          <Row
            justify={'center'}
            align={'middle'}
            style={{
              minHeight: '316px',
            }}
          >
            <Spin />
          </Row>
        )}
      </Space>
    </Col>
  );
};
