import { useQuery } from 'react-query';

import { staffApi } from '../api/staff';

export const useGetAdminUsers = (
  params: Partial<{
    page: number;
    text: string;
    roleId: number;
    limit?: number;
  }>
) => {
  return useQuery(['adminUsers', params], () => staffApi.getAdminUsers(params));
};
