import {
  Controller,
  InputNumberController,
  RadioController,
  SwitchController,
} from '@organisms';
import { Col, Form, Row, Space, Typography } from 'antd';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { InputColor } from 'src/app/framework/atoms/InputColor/InputColor.atom';
import { toFieldStatus } from 'src/app/utils/toFieldStatus';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';

import { TypeCounter } from '../../interfaces/offersInterface';

interface FormCountdownProps {
  hasCounter: boolean;
  typeCounter: TypeCounter;
  bgCounter: string;
  control: Control<any>;
}

export const FormCountdown = ({
  control,
  hasCounter,
  typeCounter,
  bgCounter,
}: FormCountdownProps) => {
  const { Text } = Typography;

  return (
    <Form
      layout='vertical'
      requiredMark='optional'
      style={{ width: '100%', paddingBlockEnd: '24px' }}
    >
      <Row style={{ alignItems: 'center' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Contador
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='hasCounter'
          sideLabel
        />
      </Row>
      {hasCounter && (
        <>
          <Row style={{ display: 'flex', paddingBlockStart: '24px' }}>
            <Col>
              <Text
                style={{
                  width: '100%',
                  lineHeight: '28px',
                  paddingInlineEnd: '16px',
                }}
              >
                Tipo do contador
              </Text>
              <RadioController
                control={control}
                name='counter.typeCounter'
                defaultValue={typeCounter}
                options={[
                  { label: 'Contador de ofertas', value: 'offerCounter' },
                  {
                    label: 'Contador de visita única',
                    value: 'uniqueVisitCounter',
                  },
                ]}
              />
            </Col>
          </Row>
          {typeCounter === 'offerCounter' && (
            <>
              <Row gutter={[24, 24]} style={{ paddingBlockStart: '24px' }}>
                <Col xs={24} lg={12}>
                  <UploadImage
                    style={{ width: '100%' }}
                    tooltip={{
                      title: () => (
                        <Typography.Text>
                          Imagem da esquerda, Logotipo Web - 170 x 100 pixels (
                          mp4, jpg, jpeg, png ou gif)
                        </Typography.Text>
                      ),
                    }}
                    control={control}
                    name={`counter.offerCounter.leftImg`}
                    label='Imagem da esquerda'
                    required={false}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <UploadImage
                    style={{ width: '100%' }}
                    tooltip={{
                      title: () => (
                        <Typography.Text>
                          Imagem da esquerda, Logotipo Responsivo - 91 x 52
                          pixels ( mp4, jpg, jpeg, png ou gif)
                        </Typography.Text>
                      ),
                    }}
                    control={control}
                    name={`counter.offerCounter.leftImgMobile`}
                    label='Imagem da esquerda Responsivo'
                    required={false}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <UploadImage
                    style={{ width: '100%' }}
                    tooltip={{
                      title: () => (
                        <Typography.Text>
                          Imagem da direita, Assets Web - 305 x 100 pixels (
                          mp4, jpg, jpeg, png ou gif)
                        </Typography.Text>
                      ),
                    }}
                    control={control}
                    name={`counter.offerCounter.rightImg`}
                    label='Imagem da direita'
                    required={false}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <UploadImage
                    style={{ width: '100%' }}
                    tooltip={{
                      title: () => (
                        <Typography.Text>
                          Imagem da esquerda, Assets Responsivo - 163 x 110
                          pixels ( mp4, jpg, jpeg, png ou gif)
                        </Typography.Text>
                      ),
                    }}
                    control={control}
                    name={`counter.offerCounter.rightImgMobile`}
                    label='Imagem da direita Responsivo'
                    required={false}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ paddingBlockStart: '20px' }}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label='Cor de fundo'
                    required
                    style={{ width: '100%' }}
                  >
                    <ControllerHookForm
                      control={control}
                      name={`counter.offerCounter.bgCounter`}
                      render={({ field, fieldState }) => {
                        const { status, help } = toFieldStatus(
                          fieldState.error
                        );
                        return (
                          <Form.Item validateStatus={status} help={help}>
                            <InputColor
                              defaultValue={bgCounter}
                              onChange={value => field.onChange(value)}
                            />
                          </Form.Item>
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {typeCounter === 'uniqueVisitCounter' && (
            <>
              <Row
                gutter={[24, 24]}
                style={{
                  paddingBlockStart: '24px',
                }}
              >
                <Col xs={24} lg={12}>
                  <Controller
                    control={control}
                    type='text'
                    name={`counter.uniqueVisitCounter.timeoutRedirectUrl`}
                    defaultValue={''}
                    placeholder='Digite a url de redirecionamento'
                    label='Url de redirecionamento (após timeout)'
                    required
                  />
                </Col>{' '}
                <Col xs={24} lg={12}>
                  <Controller
                    control={control}
                    type='text'
                    name={`counter.uniqueVisitCounter.countdownPhrase`}
                    defaultValue={''}
                    placeholder='Digite a frase a ser exibida'
                    label='Frase do contador'
                    required
                    tooltip={{
                      title: () => (
                        <Typography.Text>
                          {
                            // eslint-disable-next-line no-template-curly-in-string
                            'Utilize ${value} para determinar o local do valor do desconto.'
                          }
                          <br />
                          Ex.:{' '}
                          {
                            // eslint-disable-next-line no-template-curly-in-string
                            'Aproveite ${value} de desconto!'
                          }
                        </Typography.Text>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row
                gutter={[24, 24]}
                style={{
                  paddingBlockStart: '24px',
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item label='Definição de tempo' required>
                    <Space.Compact block>
                      <div style={{ flex: 1 }}>
                        <InputNumberController
                          control={control}
                          name='counter.uniqueVisitCounter.timerMinute'
                          placeholder='minutos'
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <InputNumberController
                          control={control}
                          name='counter.uniqueVisitCounter.timerSecond'
                          placeholder='segundos'
                          min={0}
                          max={59}
                          style={{ width: '100%' }}
                        />
                      </div>
                    </Space.Compact>
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <InputNumberController
                    control={control}
                    type='number'
                    name={`counter.uniqueVisitCounter.cacheExpirationTime`}
                    defaultValue={''}
                    placeholder='Digite o tempo em que o cache deve durar'
                    label='Expiração do cache (min)'
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Form>
  );
};
