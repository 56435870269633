import {
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import { TableComponent } from '../../../app/framework/atoms';
import { useDeleteRole } from '../hooks/useDeleteRole';
import { useGetRoles } from '../hooks/useGetRoles';
import { useGetRolesById } from '../hooks/useGetRolesById';
import { useUnlinkUsers } from '../hooks/useUnlinkUsers';
import { Role as IRole } from '../types/role';
import { CreateOrUpdateRole } from './CreateOrUpdateRole';
import { FilterByRole } from './FilterByRole';

const { Text } = Typography;
const { confirm } = Modal;

export const Role: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [selectedRole, setSelectedRole] = useState<number | undefined>(
    undefined
  );
  const [isDrawerRoleOpen, setIsDrawerRoleOpen] = useState<{
    isOpen: boolean;
    role: IRole | null;
  }>({ isOpen: false, role: null });

  const { data: rolesData, isLoading: isLoadingRoles } = useGetRoles();
  const { data: roleData, isLoading: isLoadingRole } = useGetRolesById(
    selectedRole as number
  );
  const { deleteRole } = useDeleteRole();
  const { unlikeUsers } = useUnlinkUsers();

  const showDeleteConfirm = (record: IRole) => {
    confirm({
      title: 'Excluir papel',
      centered: true,
      icon: <ExclamationCircleOutlined style={{ color: '#E84749' }} />,
      okText: 'Excluir',
      okButtonProps: { style: { backgroundColor: '#F5222D', border: 'none' } },
      cancelText: 'Cancelar',
      content: (
        <Space direction='vertical'>
          <Text style={{ marginTop: 16 }}>
            {`Tem certeza que deseja excluir o papel "${record.name}"?`}
          </Text>
          <Text style={{ marginTop: 32 }}>
            Essa ação não poderá ser desfeita.
          </Text>
        </Space>
      ),
      width: '50%',
      onOk: async () => {
        deleteRole(record.id);
      },
    });
  };

  const showUnlikeUsers = (record: IRole) => {
    confirm({
      title: 'Desvincular usuário',
      centered: true,
      icon: <ExclamationCircleOutlined style={{ color: '#E84749' }} />,
      okText: 'Desvincular',
      okButtonProps: { style: { backgroundColor: '#F5222D', border: 'none' } },
      cancelText: 'Cancelar',
      content: (
        <Space direction='vertical'>
          <Text style={{ marginTop: 16 }}>
            {`Tem certeza que deseja desvincular todos os usuários do papel "${record.name}"?`}
          </Text>
          <Text style={{ marginTop: 32 }}>
            Essa ação não poderá ser desfeita.
          </Text>
        </Space>
      ),
      width: '50%',
      onOk: async () => {
        unlikeUsers(record.id);
      },
    });
  };

  const handleMenuClick = (key: string, record: IRole) => {
    switch (key) {
      case 'edit':
        setIsDrawerRoleOpen({ isOpen: true, role: record });
        break;
      case 'delete':
        showDeleteConfirm(record);
        break;
      case 'unlinkUsers':
        showUnlikeUsers(record);
        break;
      default:
        break;
    }
  };

  const generateMenuItems = (record: IRole): MenuProps['items'] => [
    {
      key: 'edit',
      label: 'Editar',
      onClick: () => handleMenuClick('edit', record),
    },
    {
      key: 'delete',
      label: 'Excluir',
      onClick: () => handleMenuClick('delete', record),
    },
    {
      key: 'unlinkUsers',
      label: 'Desvincular usuários',
      onClick: () => handleMenuClick('unlinkUsers', record),
    },
  ];

  const TableColumns = [
    {
      title: 'Nome do papel',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descrição',
      key: 'details',
      dataIndex: 'details',
    },
    {
      title: 'Usuários',
      key: 'qntUsers',
      dataIndex: 'qntUsers',
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center' as const,
      render: (_: any, record: IRole) => (
        <Dropdown
          menu={{ items: generateMenuItems(record) }}
          trigger={['click']}
        >
          <MoreOutlined style={{ cursor: 'pointer', fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const handleChangePage = useCallback(
    (selectedPage: number) => {
      if (selectedPage !== page) setPage(selectedPage);
    },
    [page]
  );

  const handleRoleChange = (value: number) => {
    setSelectedRole(value);
    setPage(1);
  };

  const data = selectedRole ? (roleData ? [roleData] : []) : rolesData?.role;

  return (
    <div>
      <Text style={{ fontWeight: 600, marginTop: 8 }}>Busca rápida:</Text>
      <Row
        align='middle'
        justify='space-between'
        gutter={16}
        style={{ marginTop: 16 }}
      >
        <Col>
          <FilterByRole
            selectedRole={selectedRole}
            handleRoleChange={handleRoleChange}
          />
        </Col>

        <Col>
          <StyledButton
            onClick={() => setIsDrawerRoleOpen({ isOpen: true, role: null })}
          >
            <PlusCircleOutlined />
            Cadastrar novo papel
          </StyledButton>
        </Col>
      </Row>
      <TableComponent
        rowKey='id'
        pageSize={7}
        columns={TableColumns}
        data={data}
        total={data?.length}
        loading={isLoadingRoles || isLoadingRole}
        currentPage={page}
        onChangePage={selectedPage => handleChangePage(selectedPage)}
        showSizeChanger={false}
        style={{ marginTop: 24 }}
      />
      {isDrawerRoleOpen.isOpen && (
        <CreateOrUpdateRole
          open={isDrawerRoleOpen.isOpen}
          onClose={() => setIsDrawerRoleOpen({ isOpen: false, role: null })}
          role={isDrawerRoleOpen.role}
        />
      )}
    </div>
  );
};

const StyledButton = styled(Button).attrs({ type: 'primary' })`
  display: flex;
  align-items: center;
`;
