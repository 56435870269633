import { Tabs } from 'antd';
import { FC, useState } from 'react';

import { PageHeader } from '../../../app/framework/organisms';
import { useBreadcrumbs } from '../../layout/hooks/useBreadcrumbs';
import { Role } from '../components/Role';
import { Staff } from '../components/Staff';

enum ActiveTabEnum {
  STAFF = 'staff',
  ROLE = 'role',
}

export const PermissionsScreen: FC = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(
    ActiveTabEnum.STAFF
  );

  useBreadcrumbs([
    {
      label: 'Permissões',
      route: '/permissions',
    },
  ]);
  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Equipe e papéis'}
        subTitle={''}
        hasDivider={false}
      />
      <Tabs
        activeKey={activeTab}
        onChange={tab => setActiveTab(tab as ActiveTabEnum)}
        style={{ marginTop: 24 }}
        items={[
          {
            label: 'Equipe',
            key: ActiveTabEnum.STAFF,
            children: <Staff />,
          },
          {
            label: 'Papéis',
            key: ActiveTabEnum.ROLE,
            children: <Role />,
          },
        ]}
      />
    </>
  );
};
