import { baseClient } from '../../../app/baseClient';
import {
  CreateOrUpdateRole,
  PermissionsResponse,
  Role,
  RolesResponse,
} from '../types/role';
import { CreateOrUpdateAdmin, Staff } from '../types/Staff';
async function changeNewPassword(params: {
  userId: number;
  newPassword: string;
}) {
  const endpoint = `/user/admin/${params.userId}/change-password`;
  const { data } = await baseClient.put(endpoint, {
    newPassword: params.newPassword,
  });
  return data;
}

async function getAdminUsers(
  params: Partial<{
    page: number;
    limit: number;
    text: string;
    roleId: number;
  }>
) {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ''
    )
  );

  const queryParams = new URLSearchParams(
    filteredParams as Record<string, string>
  );
  const { data } = await baseClient.get<Staff>(
    `/user/admin?${queryParams.toString()}`
  );
  return data;
}

async function createAdminUser(params: CreateOrUpdateAdmin) {
  const endpoint = `/user/admin`;
  const { data } = await baseClient.post(endpoint, params);
  return data;
}
async function updateAdminUser(userId: number, params: CreateOrUpdateAdmin) {
  const endpoint = `/user/admin/${userId}`;
  const { data } = await baseClient.put(endpoint, params);
  return data;
}

async function removeStaff(userId: number) {
  const endpoint = `/user/admin/${userId}`;
  const { data } = await baseClient.delete(endpoint);
  return data;
}

async function getRoles() {
  const endpoint = `/role`;
  const { data } = await baseClient.get<RolesResponse>(endpoint);
  return data;
}

async function getRoleById(roleId: number) {
  const endpoint = `/role/id/${roleId}`;
  const { data } = await baseClient.get<Role>(endpoint);
  return data;
}

async function getPermissions() {
  const endpoint = `/permissions`;
  const { data } = await baseClient.get<PermissionsResponse>(endpoint);
  return data;
}

async function createRole(role: CreateOrUpdateRole) {
  const endpoint = `/role`;
  const { data } = await baseClient.post(endpoint, role);
  return data;
}

async function updateRole(roleId: number, role: CreateOrUpdateRole) {
  const endpoint = `/role/${roleId}`;
  const { data } = await baseClient.put(endpoint, role);
  return data;
}

async function deleteRole(roleId: number) {
  const endpoint = `/role/${roleId}`;
  const { data } = await baseClient.delete(endpoint);
  return data;
}

async function unlinkUsers(roleId: number) {
  const endpoint = `/role/${roleId}/users`;
  const { data } = await baseClient.delete(endpoint);
  return data;
}

export const staffApi = {
  changeNewPassword,
  getAdminUsers,
  createAdminUser,
  updateAdminUser,
  removeStaff,
  getRoles,
  getRoleById,
  getPermissions,
  createRole,
  deleteRole,
  unlinkUsers,
  updateRole,
};
