import { InfoCircleOutlined } from '@ant-design/icons';
import { Carousel, Col, Row, Space, Spin, Typography } from 'antd';
import styled from 'styled-components';

import { useCommunicationRenewalEmails } from '../../hooks/useCustomerCommunication';
import { CustomCard } from '../CustomCard';
import { ErrorState } from '../ErrorState';
import { EmailStatus } from './EmailStatus';

const { Text } = Typography;

export interface CommunicationCarouselProps {
  customerId: string;
}

export const CommunicationCarousel: React.FC<CommunicationCarouselProps> = ({
  customerId,
}) => {
  const { renewalEmails, isError, isLoading, refetchOnError } =
    useCommunicationRenewalEmails(customerId);

  if (isLoading) {
    return (
      <Col span={12} flex={1}>
        <CustomCard title='Email de renovação automática'>
          <Row
            justify='center'
            align='middle'
            style={{ padding: '12px 0', minHeight: '140px' }}
          >
            <Spin />
          </Row>
        </CustomCard>
      </Col>
    );
  }

  if (isError) {
    return (
      <Col span={12} flex={1}>
        <CustomCard style={{ height: '100%' }}>
          <ErrorState
            title=''
            subtitle='Infelizmente tivemos um erro ao carregar o resumo de emails de renovação enviados para o cliente.'
            buttonLabel='Recarregar'
            onClickButton={() => refetchOnError()}
          />
        </CustomCard>
      </Col>
    );
  }

  return (
    <Col span={12}>
      <CarouselContainer>
        <Carousel infinite={false} touchMove>
          {renewalEmails?.length &&
            renewalEmails.map((email, index) => (
              <Space style={{ minHeight: '216px' }} key={index}>
                <CustomCard
                  title='Email de renovação automática'
                  style={{ minHeight: '216px' }}
                >
                  <Row align={'middle'} justify={'center'}>
                    <Col flex={1}>
                      <Row justify={'center'} style={{ marginBottom: '16px' }}>
                        <Text type='secondary'>{email.subject}</Text>
                      </Row>
                      <EmailStatus currentStatus={email.status} />
                    </Col>
                  </Row>
                </CustomCard>
              </Space>
            ))}

          {!renewalEmails?.length && (
            <div>
              <CustomCard title='Email de renovação automática'>
                <Row
                  justify='center'
                  align='middle'
                  style={{ padding: '12px 0', minHeight: '140px' }}
                >
                  <Col>
                    <Space direction='vertical' align='center'>
                      <InfoCircleOutlined
                        style={{
                          color: '#E8B339',
                          fontSize: '20px',
                          marginBottom: '8px',
                        }}
                      />
                      <Typography>
                        Cliente no primeiro ciclo da assinatura. A 1ª renovação
                        ainda não ocorreu.
                      </Typography>
                    </Space>
                  </Col>
                </Row>
              </CustomCard>
            </div>
          )}
        </Carousel>
      </CarouselContainer>
    </Col>
  );
};

const CarouselContainer = styled.div`
  height: 100%;
  .ant-carousel .slick-dots li button {
    background: #ccc;
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #1668dc;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -12px;
  }
`;
