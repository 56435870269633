import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';
import { CreateOrUpdateAdmin } from '../types/Staff';

export const useCreateAdminUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (adminUser: CreateOrUpdateAdmin) => staffApi.createAdminUser(adminUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminUsers');
        message.success(`Usuário cadastrado com sucesso.`);
      },
      onError: () => {
        message.error(`O usuário não foi cadastrado. Tente novamente.`);
      },
    }
  );
};
