import { ExclamationOutlined, UnlockOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { staffApi } from '../api/staff';
import { newPasswordSchema } from '../schemas/newPasswordSchema';
import { ControlledInput } from './ui/ControlledInput';

interface ChangePasswordModalProps {
  title: string;
  showModal: boolean;
  userId: number;
  onCloseModal: () => void;
}

interface IChangePasswordForm {
  password: string;
  confirmPassword: string;
}

const { Text } = Typography;

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  title,
  showModal,
  userId,
  onCloseModal,
}) => {
  const form = useForm<IChangePasswordForm>({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(newPasswordSchema),
  });

  const onSubmit = (data: IChangePasswordForm) => {
    try {
      staffApi.changeNewPassword({
        newPassword: data.password,
        userId,
      });
      message.success('Sua senha foi alterada com Sucesso!');
      onCloseModal();
    } catch {
      message.error('A senha não foi alterada. Tente novamente.');
    }
  };

  return (
    <Modal
      title={title}
      open={showModal}
      onOk={() => {
        form.handleSubmit(onSubmit);
      }}
      onCancel={onCloseModal}
      centered
      footer={[
        <Button key='cancel' onClick={onCloseModal}>
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={form.handleSubmit(onSubmit)}
        >
          Salvar
        </Button>,
      ]}
    >
      <FormProvider {...form}>
        <Form layout='vertical' onFinish={form.handleSubmit(onSubmit)}>
          <StyledSpace>
            <Text type='secondary' style={{ fontSize: '12px' }}>
              A senha deve conter:
            </Text>
            <Text type='secondary' style={{ fontSize: '12px' }}>
              Pelo menos 8 caracteres, 1 letra maiúscula, 1 número e 1 carácter
              especial
            </Text>
          </StyledSpace>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <ControlledInput
                control={form.control}
                name='password'
                label='Nova senha'
                render={({ field, status }) => (
                  <Input.Password
                    {...field}
                    status={status}
                    placeholder={'Insira a nova senha'}
                    prefix={<UnlockOutlined style={{ color: '#1668DC' }} />}
                    suffix={status === 'error' && <RedExclamation />}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <ControlledInput
                control={form.control}
                name='confirmPassword'
                label='Confirme a nova senha'
                render={({ field, status }) => (
                  <Input.Password
                    {...field}
                    status={status}
                    placeholder={'Confirme a nova senha'}
                    prefix={<UnlockOutlined style={{ color: '#1668DC' }} />}
                    suffix={status === 'error' && <RedExclamation />}
                  />
                )}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export const RedExclamation = styled(ExclamationOutlined).attrs({
  style: {
    fontSize: '10px',
  },
})`
  color: black;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
`;

export const StyledSpace = styled(Space).attrs({
  size: 1,
  direction: 'vertical',
})`
  background-color: #1f1f1f;
  padding: 8px;
  width: 100%;
  margin-bottom: 24px;
`;
