import {
  ExclamationCircleOutlined,
  MoreOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { TableComponent } from '@atoms';
import {
  Button,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { FC, useCallback, useState } from 'react';

import { useGetAdminUsers } from '../hooks/useGetAdminUsers';
import { useRemoveStaff } from '../hooks/useRemoveStaff';
import { Admin, EStatus, STATUS_COLOR, STATUS_ICON } from '../types/Staff';
import { ChangePasswordModal } from './ChangePasswordModal';
import { CreateOrUpdateMember } from './CreateOrUpdateMember';
import { FilterByRole } from './FilterByRole';

const { Text } = Typography;
const { confirm } = Modal;

export const Staff: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<{
    isOpen: boolean;
    userId: number;
  }>({ isOpen: false, userId: 0 });
  const [isDrawerMemberOpen, setIsDrawerMemberOpen] = useState<{
    isOpen: boolean;
    adminUser: Admin | null;
  }>({ isOpen: false, adminUser: null });
  const [searchText, setSearchText] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<number | undefined>(
    undefined
  );

  const { data, isLoading } = useGetAdminUsers({
    page,
    text: searchText,
    roleId: selectedRole,
    limit: 7,
  });
  const { removeStaff } = useRemoveStaff();

  const showDeleteConfirm = (record: Admin) => {
    confirm({
      title: 'Excluir usuário',
      centered: true,
      icon: <ExclamationCircleOutlined style={{ color: '#E84749' }} />,
      okText: 'Excluir',
      okButtonProps: { style: { backgroundColor: '#F5222D', border: 'none' } },
      cancelText: 'Cancelar',

      content: (
        <Space direction='vertical'>
          <Text style={{ marginTop: 16 }}>
            {`Tem certeza que deseja excluir o usuário "${record.fullName}"?`}
          </Text>
          <Text style={{ marginTop: 32 }}>
            Essa ação não poderá ser desfeita.
          </Text>
        </Space>
      ),
      width: '50%',
      onOk: async () => {
        await removeStaff(record.id);
      },
    });
  };

  const handleMenuClick = (key: string, record: Admin) => {
    switch (key) {
      case 'edit':
        setIsDrawerMemberOpen({ isOpen: true, adminUser: record });
        break;
      case 'delete':
        showDeleteConfirm(record);
        break;
      case 'changePassword':
        setIsChangePasswordModalOpen({ isOpen: true, userId: record.id });
        break;
      default:
        break;
    }
  };

  const generateMenuItems = (record: Admin): MenuProps['items'] => [
    {
      key: 'edit',
      label: 'Editar',
      onClick: () => handleMenuClick('edit', record),
    },
    {
      key: 'delete',
      label: 'Excluir',
      onClick: () => handleMenuClick('delete', record),
    },
    {
      key: 'changePassword',
      label: 'Alterar senha',
      onClick: () => handleMenuClick('changePassword', record),
    },
  ];

  const TableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Perfil',
      key: 'accessLevel',
      dataIndex: 'accessLevel',
    },
    {
      title: 'Papéis',
      key: 'roles',
      dataIndex: 'roles',
      render: (roles: any) => <>{roles.join(', ')}</>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: EStatus) => (
        <Tag icon={STATUS_ICON[status]} color={STATUS_COLOR[status]}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      align: 'center' as const,
      render: (_: any, record: Admin) => (
        <Dropdown
          menu={{ items: generateMenuItems(record) }}
          trigger={['click']}
        >
          <MoreOutlined style={{ cursor: 'pointer', fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const handleChangePage = useCallback(
    (selectedPage: number) => {
      if (selectedPage !== page) setPage(selectedPage);
    },
    [page]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setPage(1);
  };

  const handleRoleChange = (value: number) => {
    setSelectedRole(value);
    setPage(1);
  };

  return (
    <div>
      <Text style={{ fontWeight: 600, marginTop: 8 }}>Busca rápida:</Text>
      <Row align='middle' justify='space-between' gutter={16}>
        <Col>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col>
              <Input
                placeholder='Busca por nome, email ou ID'
                suffix={<SearchOutlined />}
                style={{ width: 312 }}
                value={searchText}
                onChange={handleSearchChange}
                allowClear
              />
            </Col>
            <Col>
              <FilterByRole
                selectedRole={selectedRole}
                handleRoleChange={handleRoleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={() =>
              setIsDrawerMemberOpen({ isOpen: true, adminUser: null })
            }
          >
            Cadastrar novo membro
          </Button>
        </Col>
      </Row>
      <TableComponent
        rowKey='id'
        pageSize={7}
        columns={TableColumns}
        data={data?.users}
        total={data?.total}
        loading={isLoading}
        currentPage={page}
        onChangePage={selectedPage => handleChangePage(selectedPage)}
        showSizeChanger={false}
        style={{ marginTop: 24 }}
      />
      <ChangePasswordModal
        title='Alterar senha'
        showModal={isChangePasswordModalOpen.isOpen}
        userId={isChangePasswordModalOpen.userId}
        onCloseModal={() =>
          setIsChangePasswordModalOpen({ isOpen: false, userId: 0 })
        }
      />
      {isDrawerMemberOpen && (
        <CreateOrUpdateMember
          open={isDrawerMemberOpen.isOpen}
          onClose={() =>
            setIsDrawerMemberOpen({ isOpen: false, adminUser: null })
          }
          adminUser={isDrawerMemberOpen.adminUser}
        />
      )}
    </div>
  );
};
