import * as yup from 'yup';
import { InferType } from 'yup';

export const adminUserSchema = yup.object().shape({
  members: yup.array().of(
    yup.object().shape({
      fullName: yup
        .string()
        .min(2, 'Nome deve ter pelo menos 2 caracteres')
        .required('Nome é obrigatório'),
      email: yup
        .string()
        .email('E-mail inválido')
        .required('E-mail é obrigatório'),
      accessLevel: yup.string().required('Perfil é obrigatório'),
      roles: yup
        .array()
        .of(yup.number())
        .min(1, 'Selecione pelo menos um papel')
        .required('Papéis são obrigatórios'),
    })
  ),
});

export type AdminUserForm = InferType<typeof adminUserSchema>;
