import { Select } from 'antd';
import { FC } from 'react';

import { useGetRoles } from '../hooks/useGetRoles';

interface IFilterByRoleProps {
  selectedRole: number | undefined;
  handleRoleChange: (value: number) => void;
}

export const FilterByRole: FC<IFilterByRoleProps> = ({
  selectedRole,
  handleRoleChange,
}) => {
  const { data, isLoading } = useGetRoles();

  const roleOptions = data?.role.map(role => ({
    value: role.id,
    label: role.name,
  }));

  return (
    <Select
      placeholder='Papéis'
      options={roleOptions}
      style={{ width: 250 }}
      value={selectedRole}
      onChange={handleRoleChange}
      loading={isLoading}
      allowClear
    />
  );
};
