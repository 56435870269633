import * as yup from 'yup';

export const roleSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(30, 'Máximo de 30 caracteres')
    .required('O nome é obrigatório'),
  description: yup
    .string()
    .trim()
    .max(65, 'Máximo de 65 caracteres')
    .nullable(),
  permission: yup
    .array()
    .of(yup.number().positive('Permissão inválida'))
    .min(1, 'Selecione pelo menos uma permissão')
    .required('As permissões são obrigatórias'),
});

export type RoleForm = yup.InferType<typeof roleSchema>;
