import { baseClient } from 'src/app/baseClient';

import {
  AllowedAccess,
  ContentProfile,
  CustomerActiveDays,
  CustomerData,
  CustomerMostWatchedInstructor,
  CustomerMostWatchedProgram,
  CustomerSearch,
  CustomerSubscriptions,
  CustomerUpdateParams,
  CustomerWatchedClassesByDevice,
  Email,
  EmailWithPagination,
  ICustomerHistory,
  linkToResetPassword,
  Recommendation,
  UsageEngagement,
} from '../types/customer';

export interface WithPagination<T> {
  count: number;
  rows: T;
}

async function fetchCustomerUsageEngagement(
  customerId: string
): Promise<UsageEngagement> {
  const endpoint = `/customer/${customerId}/usage-engagement`;

  const { data } = await baseClient.get<UsageEngagement>(endpoint);
  return data;
}

async function fetchCustomerData(searchParam: string): Promise<CustomerData> {
  const endpoint = `/customer/${searchParam}`;

  const { data } = await baseClient.get<CustomerData>(endpoint);
  return data;
}

async function fetchCustomerSubscriptions(
  customerId: string,
  page: number,
  limit: number
): Promise<WithPagination<CustomerSubscriptions[]>> {
  const endpoint = `/customer/${customerId}/subscriptions?page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<
    WithPagination<CustomerSubscriptions[]>
  >(endpoint);

  return data;
}

async function fetchCustomerActiveDays(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/active-days?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerActiveDays>(endpoint);
  return data;
}

async function fetchCustomerMostWatchedPrograms(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/most-watched-programs?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerMostWatchedProgram[]>(endpoint);
  return data;
}

async function fetchCustomerMostWatchedInstructors(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/most-watched-instructors?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerMostWatchedInstructor[]>(
    endpoint
  );
  return data;
}

async function fetchCustomerWatchedClassesByDevices(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/access-history-by-devices?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerWatchedClassesByDevice[]>(
    endpoint
  );
  return data;
}

async function fetchCustomerRecommendations(
  customerId: string,
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) {
  const endpoint = `/customer/${customerId}/engagement/recommendations?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<WithPagination<Recommendation[]>>(
    endpoint
  );
  return data;
}

async function fetchCustomerSearches(
  customerId: string,
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) {
  const endpoint = `/customer/${customerId}/engagement/searches?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<WithPagination<CustomerSearch[]>>(
    endpoint
  );
  return data;
}

async function fetchCustomerCommunicationEmails(
  customerId: string,
  startDate?: string,
  endDate?: string,
  page?: number,
  limit?: number,
  subject?: string,
  status?: string
) {
  const query = new URLSearchParams({
    ...(subject && { subject }),
    ...(status && { status }),
    ...(startDate && { firstDate: startDate }),
    ...(endDate && { lastDate: endDate }),
  });
  const queryString = query.toString();

  const endpoint =
    `/customer/${customerId}/communications?page=${page}&limit=${limit}` +
    (queryString ? `&${queryString}` : '');

  const { data } = await baseClient.get<EmailWithPagination>(endpoint);

  return data;
}

async function fetchCustomerLastCommunicationMail(customerId: string) {
  const endpoint = `/customer/${customerId}/communications/last`;

  const { data } = await baseClient.get<Email>(endpoint);
  return data;
}

async function fetchCustomerCommunicationRenewalMails(customerId: string) {
  const endpoint = `/customer/${customerId}/communications/renewal`;

  const { data } = await baseClient.get<EmailWithPagination>(endpoint);
  return data;
}

async function updateCustomer(params: CustomerUpdateParams) {
  const { id, ...payload } = params;
  const endpoint = `/customer/${id}`;
  return await baseClient.put(endpoint, payload);
}

async function sendEmailFirstAccess(param: { id?: string; email?: string }) {
  const endpoint = `/customer/send-email`;
  return await baseClient.post(endpoint, {
    email: param.email,
  });
}

async function sendEmailFirstAccessv2(customerId: string) {
  const endpoint = `/customer/${customerId}/first-access-email`;
  return await baseClient.post(endpoint);
}

async function changePassword(param: {
  password: string;
  id?: string;
  reason: string;
}) {
  const endpoint = `/customer/${param.id}/password`;
  return await baseClient.post(endpoint, {
    password: param.password,
    reason: param.reason,
  });
}

async function fetchAccessAllowed(customerId: string) {
  const endpoint = `/customer/${customerId}/access-allowed`;
  const { data } = await baseClient.get<AllowedAccess[]>(endpoint);
  return data;
}

async function allowAccess(
  customerId: string,
  profileId: string,
  numberOfAccessDays: number,
  reason: string
) {
  const endpoint = `/customer/${customerId}/profile/${profileId}/access`;
  return await baseClient.post(endpoint, {
    numberOfAccessDays,
    reason,
  });
}

async function removeAccess(customerId: string, profileId: number) {
  const endpoint = `/customer/${customerId}/profile/${profileId}`;
  return await baseClient.delete(endpoint);
}

async function fetchLinkToResetPassword(customerId: string) {
  const endpoint = `/customer/${customerId}/password-reset-link`;
  const { data } = await baseClient.get<linkToResetPassword>(endpoint);
  return data;
}

async function generateLinkToResetPassword(customerId: string) {
  const endpoint = `/customer/${customerId}/password-reset-link`;
  return await baseClient.post(endpoint);
}

async function changeUserPassword(
  customerId: string,
  password: string,
  reason: string
) {
  const endpoint = `/customer/${customerId}/password`;
  return await baseClient.patch(endpoint, {
    password,
    reason,
  });
}

async function fetchHistory(customerId: string) {
  const endpoint = `/customer/${customerId}/history`;
  const { data } = await baseClient.get<ICustomerHistory[]>(endpoint);
  return data;
}

async function fetchContentProfiles() {
  const endpoint = '/profiles';
  const { data } = await baseClient.get<ContentProfile[]>(endpoint);
  return data;
}

export const customerApi = {
  allowAccess,
  changePassword,
  changeUserPassword,
  fetchAccessAllowed,
  fetchContentProfiles,
  fetchCustomerActiveDays,
  fetchCustomerCommunicationEmails,
  fetchCustomerCommunicationRenewalMails,
  fetchCustomerData,
  fetchCustomerLastCommunicationMail,
  fetchCustomerMostWatchedInstructors,
  fetchCustomerMostWatchedPrograms,
  fetchCustomerRecommendations,
  fetchCustomerSearches,
  fetchCustomerSubscriptions,
  fetchCustomerUsageEngagement,
  fetchCustomerWatchedClassesByDevices,
  fetchHistory,
  fetchLinkToResetPassword,
  generateLinkToResetPassword,
  removeAccess,
  sendEmailFirstAccess,
  sendEmailFirstAccessv2,
  updateCustomer,
};
