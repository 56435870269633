import { ExclamationOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useCreateRole } from '../hooks/useCreateRole';
import { useGetPermissions } from '../hooks/useGetPermissions';
import { useUpdateRole } from '../hooks/useUpdateRole';
import { roleSchema } from '../schemas/roleSchema';
import { Role } from '../types/role';
import { ControlledInput } from './ui/ControlledInput';

interface FormValues {
  name: string;
  details: string;
  permission: number[];
}

interface CreateOrUpdateRoleProps {
  open: boolean;
  onClose: () => void;
  role?: Role | null;
}

const EMPTY_ROLE: FormValues = {
  name: '',
  details: '',
  permission: [],
};
const { Text } = Typography;

export const CreateOrUpdateRole: React.FC<CreateOrUpdateRoleProps> = ({
  open,
  onClose,
  role,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const form = useForm<FormValues>({
    defaultValues: EMPTY_ROLE,
    resolver: yupResolver(roleSchema),
  });

  const { control, handleSubmit, reset } = form;
  const { data } = useGetPermissions();
  const { mutate: createRole } = useCreateRole();
  const { mutate: updateRole } = useUpdateRole();

  useEffect(() => {
    if (open) {
      reset(
        {
          ...role,
          permission: role?.permission.map(p => p.id) ?? [],
        } ?? EMPTY_ROLE
      );
    }
  }, [open, role, reset]);

  const handleSelectAll = (
    field: { onChange: (arg0: number[]) => void },
    allPermissions: number[],
    isAllSelected: boolean
  ) => {
    const newValue = isAllSelected ? [] : allPermissions;
    field.onChange(newValue);
    setDropdownOpen(false);
  };

  const onSubmit = (data: FormValues) => {
    const payload = {
      name: data.name,
      details: data.details,
      permissionsIds: data.permission,
    };
    if (role) {
      updateRole({ roleId: role.id, role: payload });
    } else {
      createRole(payload);
    }
    onClose();
  };

  return (
    <Drawer
      title={role ? 'Editar Papel' : 'Cadastrar Novo Papel'}
      width={685}
      open={open}
      onClose={onClose}
      footer={
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type='primary' onClick={handleSubmit(onSubmit)}>
            Salvar
          </Button>
        </Space>
      }
    >
      <FormProvider {...form}>
        <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ControlledInput
                control={control}
                name='name'
                label='Nome'
                render={({ field, status }) => (
                  <Input
                    {...field}
                    status={status}
                    suffix={status === 'error' && <RedExclamation />}
                    showCount
                    maxLength={30}
                  />
                )}
                required
              />
            </Col>

            <Col span={24}>
              <ControlledInput
                control={control}
                name='details'
                label='Descrição'
                render={({ field, status }) => (
                  <Input.TextArea
                    {...field}
                    status={status}
                    suffix={status === 'error' && <RedExclamation />}
                    showCount
                    maxLength={65}
                  />
                )}
              />
            </Col>

            <Col span={24}>
              <ControlledInput
                control={control}
                name='permission'
                label='Selecione as permissões a serem atribuídas:'
                render={({ field, status }) => {
                  const allPermissions =
                    data?.permissions?.map(p => p.id) ?? [];
                  const isAllSelected =
                    field.value.length === allPermissions.length;

                  return (
                    <Select
                      {...field}
                      mode='multiple'
                      placeholder='Busque por permissão'
                      open={dropdownOpen}
                      onDropdownVisibleChange={setDropdownOpen}
                      options={[
                        {
                          label: (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '4px 0',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                              }}
                              onClick={() =>
                                handleSelectAll(
                                  field,
                                  allPermissions,
                                  isAllSelected
                                )
                              }
                            >
                              <Checkbox checked={isAllSelected} />
                              <Text style={{ marginLeft: '8px' }}>
                                Selecionar Todos
                              </Text>
                            </div>
                          ),
                          value: 'select_all',
                          disabled: true,
                        },

                        ...(data?.permissions?.map(p => ({
                          label: (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                cursor: 'pointer',
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                const newValue = field.value.includes(p.id)
                                  ? field.value.filter(
                                      (id: number) => id !== p.id
                                    )
                                  : [...field.value, p.id];

                                field.onChange(newValue);
                              }}
                            >
                              <Checkbox checked={field.value.includes(p.id)} />
                              {p.name}
                            </div>
                          ),
                          value: p.id,
                        })) ?? []),
                      ]}
                      value={field.value || []}
                      onChange={selectedValues =>
                        field.onChange(selectedValues)
                      }
                      status={status}
                      suffixIcon={status === 'error' && <RedExclamation />}
                      allowClear
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString().toLowerCase() ?? ''
                        ).includes(input.toLowerCase())
                      }
                    />
                  );
                }}
                required
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export const RedExclamation = styled(ExclamationOutlined).attrs({
  style: { fontSize: '10px' },
})`
  color: black;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
`;
