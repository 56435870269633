import { env } from 'src/app/env';

import { subscriptionPeriodOptions } from '../constants';
import { FormResponse, Offer } from '../interfaces/offersInterface';

export const formOfferFactory = (
  data: Offer,
  definePlanType: (arg0?: string) => string | undefined
): FormResponse => {
  return {
    offerName: data.name,
    offerDescription: data.description,
    bannerWeb:
      data.checkout?.bannerWeb && `${env.ASSETS}${data.checkout?.bannerWeb}`,
    bannerMobile:
      data.checkout?.bannerMobile &&
      `${env.ASSETS}${data.checkout?.bannerMobile}`,
    bannerSideWeb:
      data.checkout?.bannerSideWeb &&
      `${env.ASSETS}${data.checkout?.bannerSideWeb}`,
    logo: data.checkout?.logo && `${env.ASSETS}${data.checkout?.logo}`,
    fullPrice: data.fullPrice.toString(),
    displayPrice: data.displayPrice ? data.displayPrice.toString() : '',
    installments: data.installments.toString(),
    startDate: data.startDate,
    endDate: data?.endDate,
    purchaseBtnLabel: data.checkout?.purchaseBtnLabel ?? '',
    subscriptionPeriod: String(
      data?.subscriptionPeriod || subscriptionPeriodOptions[0].value
    ),
    accessProfileId: data.accessProfileId ?? '',
    googleAnalyticsIds: data.checkout?.googleAnalyticsIds ?? '',
    facebookPixels: data.checkout?.facebookPixels ?? '',
    isMailPromotional: data.tag === 'promotional' ? true : false,
    disclaimer: data.disclaimer ?? '',
    renewalOfferId: String(data.renewalOfferId ?? ''),
    privacyPolicyRef: data.checkout?.privacyPolicyRef ?? '',
    termsRef: data.checkout?.termsRef ?? '',
    plan: definePlanType(data?.accessProfileId ?? undefined) ?? '',
    offerStandart: data?.replaceDefaultOffer || false,
    hasCounter: data.checkout?.enableCounter || !!data.uniqueVisitCounter,
    counter: {
      typeCounter: !!data.uniqueVisitCounter
        ? 'uniqueVisitCounter'
        : 'offerCounter',
      offerCounter: {
        rightImg: data.checkout?.bannerRightWebCounter,
        rightImgMobile: data.checkout?.bannerRightMobileCounter,
        leftImg: data.checkout?.bannerLeftCounter,
        leftImgMobile: data.checkout?.bannerLeftMobileCounter,
        bgCounter: data.checkout?.colorBackgroundCounter ?? '',
      },
      uniqueVisitCounter: {
        timeoutRedirectUrl: data.uniqueVisitCounter?.timeoutRedirectUrl ?? '',
        timerMinute: String(data.uniqueVisitCounter?.timerMinute ?? ''),
        timerSecond: String(data.uniqueVisitCounter?.timerSecond ?? ''),
        countdownPhrase: data.uniqueVisitCounter?.countdownPhrase ?? '',
        cacheExpirationTime: String(
          data.uniqueVisitCounter?.cacheExpirationTime ?? ''
        ),
      },
    },
    isDisplayCoupon: data?.isDisplayCoupon,
    isAvailableNuPay: data?.isAvailableNuPay,
    isAvailablePagaleve: data?.isAvailablePagaleve,
    renewalDisabled: data?.renewalDisabled,
    hasPromotionalOffer: !!data.offerFormat,
    promotionalAmount: String(
      !!data.offerFormat ? data.offerFormat?.promotionalAmount ?? '' : ''
    ),
    qtdPromotionalDays: String(
      !!data.offerFormat ? data.offerFormat?.qtdPromotionalDays ?? '' : ''
    ),
    qtdDelayedCaptureDays: String(
      !!data.offerFormat ? data.offerFormat?.qtdDelayedCaptureDays ?? '' : ''
    ),
    isPhysicProduct: data.productSellGroups?.prodType === 'fisico',
    profileId: String(data.profileId ?? ''),
    warrantyDays: String(data.warrantyDays ?? ''),
    physicProduct: {
      isFreeShipping: data.isFreeShipping,
      primaryColor: data.checkout?.primaryColor ?? '#FF4F00',
      footerText: data.checkout?.footerText ?? '',
      seo: {
        title: data.checkout?.title ?? '',
        smallDescription: data.checkout?.smallDescription ?? '',
        largeDescription: data.checkout?.largeDescription ?? '',
        image: data.checkout?.image
          ? `${env.ASSETS}${data.checkout?.image}`
          : '',
        favicon: data.checkout?.favicon
          ? `${env.ASSETS}${data.checkout.favicon}`
          : '',
        siteName: data.checkout?.siteName ?? '',
        keywords: data.checkout?.keywords ? data.checkout.keywords : [],
      },
    },
  };
};
