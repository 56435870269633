import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';
import { CreateOrUpdateRole } from '../types/role';

export const useUpdateRole = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ roleId, role }: { roleId: number; role: CreateOrUpdateRole }) =>
      staffApi.updateRole(roleId, role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        message.success('Papel editado com sucesso!');
      },
      onError: () => {
        message.error('Falha ao editar o papel. Tente novamente!');
      },
    }
  );
};
