import {
  DeleteOutlined,
  ExclamationOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useCreateAdminUser } from '../hooks/useCreateAdminUser';
import { useGetRoles } from '../hooks/useGetRoles';
import { useUpdateAdminUser } from '../hooks/useUpdateAdminUser';
import { adminUserSchema } from '../schemas/adminUserSchema';
import { Admin, CreateOrUpdateAdmin } from '../types/Staff';
import { ControlledInput } from './ui/ControlledInput';

const { Option } = Select;

type UpdateAdminUser = Omit<Admin, 'roles'> & { roles: number[] };

interface CreateOrUpdateMemberProps {
  open: boolean;
  onClose: () => void;
  adminUser: Admin | null;
}

const EMPTY_MEMBER: CreateOrUpdateAdmin = {
  fullName: '',
  email: '',
  accessLevel: '',
  roles: [],
};

export const CreateOrUpdateMember: React.FC<CreateOrUpdateMemberProps> = ({
  open,
  onClose,
  adminUser,
}) => {
  const isEditing = !!adminUser;

  const form = useForm<{ members: CreateOrUpdateAdmin[] }>({
    resolver: yupResolver(adminUserSchema),
  });

  const { control, handleSubmit, reset } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members',
  });

  const { mutateAsync: createAdminUser } = useCreateAdminUser();
  const { mutate: updateAdminUser } = useUpdateAdminUser();
  const { data: allRoles } = useGetRoles();

  const updatedUser = useMemo(() => {
    const selectedRoles = adminUser?.roles
      .map(roleName => {
        const role = allRoles?.role.find(
          r =>
            r.name.toLocaleLowerCase().trim() ===
            roleName.toLocaleLowerCase().trim()
        );

        return role ? role.id : undefined;
      })
      .filter(roleId => roleId !== undefined);

    return {
      ...adminUser,
      roles: selectedRoles,
    } as UpdateAdminUser;
  }, [adminUser, allRoles]);

  useEffect(() => {
    if (adminUser) {
      reset({ members: [updatedUser] });
    } else {
      reset({ members: [EMPTY_MEMBER] });
    }
  }, [adminUser, reset, updatedUser]);

  const onSubmit = async (data: { members: CreateOrUpdateAdmin[] }) => {
    const promises = data.members.map(memberData => {
      const payload = {
        fullName: memberData.fullName,
        email: memberData.email,
        roles: memberData.roles,
        accessLevel: memberData.accessLevel,
      };

      if (isEditing) {
        return updateAdminUser({ userId: adminUser.id, updatedAdmin: payload });
      } else {
        return createAdminUser(payload);
      }
    });

    await Promise.all(promises);
    onClose();
  };

  const handleRemove = (index: number) => {
    confirm({
      title: 'Tem certeza que deseja excluir o cadastro adicional?',
      okText: 'Sim, excluir',
      cancelText: 'cancelar',
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          border: 'none',
        },
      },
      style: {
        top: '35%',
      },
      onOk: () => remove(index),
    });
  };

  return (
    <Drawer
      title={
        isEditing
          ? 'Editar membro da equipe'
          : 'Cadastrar novo membro da equipe'
      }
      width={1113}
      open={open}
      onClose={onClose}
      footer={
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type='primary' onClick={handleSubmit(onSubmit)}>
            {isEditing ? 'Atualizar' : 'Salvar'}
          </Button>
        </Space>
      }
    >
      <FormProvider {...form}>
        <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {fields.map((field, index) => (
              <Row key={field.id} gutter={16}>
                <Col span={6}>
                  <ControlledInput
                    control={control}
                    name={`members.${index}.fullName`}
                    label='Nome'
                    render={({ field, status }) => (
                      <Input
                        {...field}
                        status={status}
                        suffix={status === 'error' && <RedExclamation />}
                      />
                    )}
                    required
                  />
                </Col>
                <Col span={6}>
                  <ControlledInput
                    control={control}
                    name={`members.${index}.email`}
                    label='Email'
                    render={({ field, status }) => (
                      <Input
                        {...field}
                        status={status}
                        suffix={status === 'error' && <RedExclamation />}
                      />
                    )}
                    required
                  />
                </Col>
                <Col span={4}>
                  <ControlledInput
                    control={control}
                    name={`members.${index}.accessLevel`}
                    label='Perfil'
                    render={({ field, status }) => (
                      <Select
                        {...field}
                        status={status}
                        value={field.value ? field.value : undefined}
                        placeholder='Selecione'
                      >
                        <Option value='Administrador'>Administrador</Option>
                        <Option value='Padrão'>Padrão</Option>
                      </Select>
                    )}
                    required
                  />
                </Col>
                <Col span={!isEditing && fields.length > 1 ? 7 : 8}>
                  <ControlledInput
                    control={control}
                    name={`members.${index}.roles`}
                    label='Papéis'
                    render={({ field, status }) => (
                      <Select
                        {...field}
                        mode='tags'
                        placeholder='Selecione os papéis'
                        options={allRoles?.role.map(r => ({
                          value: r.id,
                          label: r.name,
                        }))}
                        value={field.value || []}
                        onChange={field.onChange}
                        status={status}
                      />
                    )}
                    required
                  />
                </Col>
                {!isEditing && fields.length > 1 && (
                  <Col span={1}>
                    <DeleteButton
                      type='default'
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </DeleteButton>
                  </Col>
                )}
              </Row>
            ))}
          </Space>

          {!isEditing && (
            <Row justify='end' style={{ marginTop: 16 }}>
              <Button
                icon={<PlusOutlined />}
                style={{ border: 'none', color: '#177ddc' }}
                onClick={() => append(EMPTY_MEMBER)}
              >
                Adicionar mais Membros
              </Button>
            </Row>
          )}
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export const RedExclamation = styled(ExclamationOutlined).attrs({
  style: {
    fontSize: '10px',
  },
})`
  color: black;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
`;

export const DeleteButton = styled(Button)`
  color: #a61d25;
  border: 1px solid #a61d25;
  margin-top: 26px;
  display: flex;
  max-width: 40px;
  align-items: center;
  justify-content: center;

  &:hover {
    color: red;
    border: 1px solid red;
  }
`;
