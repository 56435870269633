import * as yup from 'yup';
import { InferType } from 'yup';

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])(?=.*[a-zA-Z]).{8,}$/,
      'A senha deve conter pelo menos 1 letra maiúscula, 1 número e 1 caractere especial'
    )
    .required('Esse campo é obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
    .required('Esse campo é obrigatório'),
});

export type NewPasswordForm = InferType<typeof newPasswordSchema>;
