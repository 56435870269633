import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { staffApi } from '../api/staff';
import { CreateOrUpdateRole } from '../types/role';

export const useCreateRole = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (newRole: CreateOrUpdateRole) => staffApi.createRole(newRole),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        message.success('Papel criado com sucesso!');
      },
      onError: () => {
        message.error('Erro na criação do papel. Tente novamente.');
      },
    }
  );
};
